import AnniversaryIcon from '../assets/anniversary.svg';
import Anniversary25thIcon from '../assets/Anniversary25th.svg';
import BirthdayIcon from '../assets/Birthday.svg';
import Birthday50thIcon from '../assets/Birthday50th.svg';
import EngagementIcon from '../assets/Engagement.svg';
import Farewell from '../assets/Farewell.svg';
import GraduationIcon from '../assets/graduation.png';
import RecognitionIcon from '../assets/Recognition.svg';
import RetirementIcon from '../assets/Retirement.svg';
import ReunionIcon from '../assets/Reunion.svg';
import SastiPoorthiIcon from '../assets/SastiPoorthi.svg';
import SpecialDayIcon from '../assets/SpecialDay.svg';
import WeddingIcon from '../assets/wedding.svg';
import WorkAnniversaryIcon from '../assets/WorkAniversary.svg';


  
export const eventType = [
    { value: 'Anniversary', label: 'Anniversary', icon: AnniversaryIcon},
    { value: 'Birthday', label: 'Birthday', icon: BirthdayIcon},
    { value: 'Engagement', label: 'Engagement', icon: EngagementIcon},
    { value: 'Farewell', label: 'Farewell', icon: Farewell},
    { value: 'Graduation', label: 'Graduation', icon: GraduationIcon},
    { value: 'Recognition', label: 'Recognition', icon: RecognitionIcon},
    { value: 'Retirement', label: 'Retirement', icon: RetirementIcon},
    { value: 'Reunion', label: 'Reunion', icon: ReunionIcon},
    { value: 'Sasti Poorthi', label: 'Sasti Poorthi', icon: SastiPoorthiIcon},
    { value: 'Special Day', label: 'Special Day', icon: SpecialDayIcon},
    { value: 'Wedding', label: 'Wedding', icon: WeddingIcon},
    { value: 'Work Anniversary', label: 'Work Anniversary', icon: WorkAnniversaryIcon},
    { value: '25th Anniversary', label: '25th Anniversary', icon: Anniversary25thIcon},
    { value: '50th Birthday', label: '50th Birthday', icon: Birthday50thIcon}
]