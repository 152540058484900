import { useState, useEffect } from 'react';

import { Box, Grid } from '@mui/material';

import EventDetailsCard from '../../components/Shared/Cards/EventDetailsCard';

function EventCard({ events, handleGetFilteredEvents, past }: any) {
    const [eventItems, setEventItems] = useState(events || []);

    useEffect(() => {
        setEventItems(events);
    }, [events]);

    return (
        <Box sx={{ width: '100%', my: 3 }}>
            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 15, lg:18, xl:12}} >
                {eventItems.map((event: any) => (
                    <Grid item xs={2} sm={2} md={3} lg={3} xl={2}>
                        <EventDetailsCard
                            eventType={event?.event_type}
                            eventName={event?.name}
                            eventImageUrl={event?.event_image_url}
                            eventDate={event?.event_date}
                            redirectUrl={`/view-event/${event?.event_id}`}
                            pastEvent={past}
                            eventStatus={event?.status}
                            isDelete={event?.is_deleted}
                            eventPublished={event?.is_published}
                            eventId={event?.event_id}
                            handleGetFilteredEvents={handleGetFilteredEvents}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export { EventCard };
