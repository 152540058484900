import { useState, useEffect, useCallback } from 'react';

import { Grid,Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

import EventImageUploader from './EventImageUploader'
import TimeCounting from './TimeCounting';
import BirthdayCakeIcon from '../../assets/birthday-cake.svg';
import EventViewHeaderCardBg from '../../assets/EventViewHeaderCardBg.svg';
import { ReactComponent as SingleUserIcon } from '../../assets/single-user-icon.svg';
import EllipsisLabel from '../../components/Shared/Label/EllipsisLabel';
import Loader from '../../components/Shared/Loader';
import {handleFileSelect} from '../../helpers/viewEventHeaderCardHelper';


// eslint-disable-next-line max-lines-per-function
function HeaderCard(props: any) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isUploading, setIsUploading] = useState(false);
    const time = new Date(props?.event?.deadline);
    time.setSeconds(time.getSeconds());
    const [tempEventImage, setTempEventImage] = useState('');
    const previousPath = location.pathname;
    const calculateTimeLeft = useCallback(() => {
        const eventDate = moment(props?.event?.deadline)
        const now = moment()
        const deadline = eventDate.clone().startOf('day')
        const duration = moment.duration(deadline.diff(now))

        if(now.isAfter(deadline, 'day')){
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        if (now.isSame(deadline, 'day')){
            const totalSecondsInADay = 24 * 60 * 60
            const expiredSeconds = now.diff(deadline, 'seconds')
            const remainingSeconds = totalSecondsInADay - expiredSeconds

            const hours = Math.floor(remainingSeconds / 3600)
            const minutes = Math.floor((remainingSeconds % 3600) / 60)
            const seconds = remainingSeconds % 60
            return { days: 0, hours, minutes, seconds }
        }
            return {
                hours: duration.hours(),
                days: Math.floor(duration.asDays()),
                minutes: duration.minutes(),
                seconds: duration.seconds()
            }
        
        
    }, [props?.event?.deadline])
        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
    
        useEffect(() => {
            const interval = setInterval(() => {
                setTimeLeft(calculateTimeLeft())
            }, 1000)
     
            return () => clearInterval(interval)
        }, [calculateTimeLeft])
        
 
    return (
        <Box
            sx={{
                backgroundColor: 'primary.light',
                borderRadius: { xs: '4px', sm: '4px' },
                mt:{xs:-3.7, sm:0}
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    background: `transparent url(${EventViewHeaderCardBg}) 0% 0% no-repeat padding-box`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: { xs: '440px', sm: '240px' },
                    width: '100%'
                }}
            >
                <Grid
                    container
                    component="main"
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Grid item xs={12} sm={8}>
                        <Box
                            sx={{
                                padding: { 
                                    xs: '30px 30px 0 30px',
                                    sm: '40px 30px'
                                },
                                width: '100%'
                            }}
                        >
                            <Grid
                                container
                                component="main"
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={3}
                            >
                                <Grid item xs={4} sm={2.5} md={2.5} >
                                    <EventImageUploader
                                        tempEventImage={tempEventImage}
                                        eventImageUrl={props?.event?.event_image_url}
                                        handleFileSelect={(files) => handleFileSelect(props, setTempEventImage, files, setIsUploading)
                                        }
                                        />
                                        {isUploading && <Loader />}
                                </Grid>
                                <Grid item xs={8} sm={9.5} md={9.5} sx={{ pt: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={BirthdayCakeIcon}
                                            sx={{
                                                height: '30px',
                                                width: '30px',
                                                display: 'inline-block',
                                                mr: 1
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                bgcolor: '#ffffff99',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    font: 'normal normal normal 11px/14px Inter',
                                                    display: 'inline-block',
                                                    p: 0.5,
                                                    m: 'auto'
                                                }}
                                            >
                                                {moment(
                                                    props?.event?.event_date
                                                ).format('MM-DD-YYYY')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <EllipsisLabel font="normal normal 600 19px/24px Inter" 
                                        label={props} 
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex flex-row',
                                            alignItems: 'center',
                                            mt: 1
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mr: 1
                                            }}
                                        >
                                            <SingleUserIcon />
                                            <Typography
                                                sx={{
                                                    font: 'normal normal normal 11px/14px Inter',
                                                    color: '#AABBCC',
                                                    pl: '5px'
                                                }}
                                            >
                                                 <span style={{ fontWeight: 'bold' }}>
                                                 {props?.invitesCount}
                                                </span>
                                                {' '}
                                                Participants Invited
                                            </Typography>
                                        </Box>
                                        <Typography
                                            sx={{
                                                font: 'normal normal normal 11px/14px Inter',
                                                color: '#AABBCC',
                                                pt: '5px'
                                            }}
                                        >
                                            Target Date for Publishing event{' '}
                                            {moment(props?.event?.actual_event_date).format('MM-DD-YYYY')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                font: 'normal normal normal 11px/14px Inter',
                                                color: '#AABBCC',
                                                pt: '5px',
                                                pb:'5px'
                                            }}
                                        >
                                            {(props?.event?.message)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} justifyItems="right" justifyContent="right" 
                        sx={{
                            ...((props?.event?.message?.length >= 150 || props?.event?.name?.length >= 50) && { mb:{xs:3.7, sm:0} })
                        }}
                    >
                        <TimeCounting
                            time={timeLeft}
                            onClick={() => {
                                navigate(`/upload-images/${props?.event?.event_id}`, { state: { previousPath } });
                            }}
                            publishVideoUrl={props?.event?.publish_video_url}
                            message={props?.event?.message}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
}

export { HeaderCard };