import React, { useState } from 'react';

import PhotoIcon from '@mui/icons-material/Photo';
import { Box, IconButton, Dialog, DialogActions, DialogContent, Slider } from '@mui/material';
import ReactEasyCrop from 'react-easy-crop';

import { ReactComponent as AddIcon } from '../../assets/circle-plus-solid-white-icon.svg';
import UserIcon from '../../assets/user.svg';
import CustomLoadingButton from '../../components/Shared/Button/CustomLoadingButton';
import { OutlinedButtons } from '../../components/Shared/Button/OutlinedButtons'
import { baseUrlToFile, getCroppedImg } from '../../helpers/eventImageUploaderHelper';

interface EventImageUploaderProps {
  tempEventImage: string;
  eventImageUrl: string;
  handleFileSelect: (files: FileList | null) => void;
}

const EventImageUploader = ({
  tempEventImage,
  eventImageUrl,
  handleFileSelect
}: EventImageUploaderProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = useState<any>(null);
  const [image, setImage] = useState(tempEventImage || '');
  const [openDialog, setOpenDialog] = useState(false);
  const [fileName, setFileName] = useState('');
  const [zoom, setZoom] = useState(1);
  

  const onCropComplete = (_: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const imgName = file.name;
      setFileName(imgName);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
        setZoom(1);
        setOpenDialog(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropSave = async () => {
    if (croppedArea) {
        const croppedImage = await getCroppedImg(image, croppedArea);
        const file = baseUrlToFile(croppedImage, fileName);
        const fileList = new DataTransfer();
        fileList.items.add(file);
        handleFileSelect(fileList.files);
        setOpenDialog(false);
    }
  };

  const handleZoomChange = (newZoom: number) => {
    if (newZoom >= 1 && newZoom <= 3) setZoom(newZoom); 
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          width: '100px',
          height: '100px',
          border: tempEventImage
            ? '2px solid #FFFFFF'
            : eventImageUrl !== ''
            ? '2px solid #FFFFFF'
            : 'none',
          borderRadius: '50%',
          background: `url(${
            tempEventImage ||
            (eventImageUrl !== '' && eventImageUrl !== null ? eventImageUrl : UserIcon)
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '@media (max-width:600px)': {
            width: '80px',  
            height: '80px'
          },
          '@media (min-width:600px) and (max-width: 1024px)': {
            width: '90px',  
            height: '90px' 
          },
          '@media(min_width: 1024)':{
            width: '120px',
            height: '120px'
          }
        }}
      />
      <IconButton
        component="label"
        sx={{
          position: 'absolute',
          marginTop: '-30px',
          marginLeft: '70px',
          backgroundColor: '#7FD14D',
          '&:hover': {
            backgroundColor: '#54a324'
          },
          zIndex: 1000,
          '@media (max-width:600px)': {
            marginTop: '-25px', 
            marginLeft: '60px'
          },
          '@media (min-width:600px) and (max-width: 1024px)': {
            marginTop: '-28px', 
            marginLeft: '65px'
          }
        }}
      >
        <input hidden accept="image/*" type="file" onChange={onImageUpload} />
        <AddIcon />
      </IconButton>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent sx={{
          '@media (max-width:600px)': {
            width: '100%',  
            height: '250px'
          },
          '@media (min-width:600px) and (max-width: 1024px)': {
            width: '400px', 
            height: '320px'
          },
          '@media (min-width: 1024px)': {
            width: '500px',  
            height: '340px' 
          }
        }}>
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ReactEasyCrop
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={180 / 140} 
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        </DialogContent>
        <Box sx={{width:{xs:'300px',sm:'400px',md: '500px'},display: 'flex',justifyContent: 'center', alignItems: 'center',mx: 'auto', pl: { xs: 2, sm: 5 }, pr: { xs: 2, sm: 5 } }}>
            <IconButton
              onClick={() => handleZoomChange(zoom - 0.1)}
              disabled={zoom <= 1}
            >
              -
             <PhotoIcon fontSize="small"  sx={{width:15,height:15}}/>
            </IconButton>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(_, value) => handleZoomChange(value as number)}
              sx={{ width: '200px', mx: 2}}
            />
            <IconButton
              onClick={() => handleZoomChange(zoom + 0.1)}
              disabled={zoom >= 3}
            >
              <PhotoIcon fontSize="medium" sx={{width:20,height:20}} />
              +
            </IconButton>
          </Box>
          <DialogActions sx={{justifyContent: 'center',  pr: { xs: 2, sm: 3.25 },  pb: { xs: 1.5, sm: 2.5 }}}>
          <OutlinedButtons onClick={() => setOpenDialog(false)} text='Cancel' width={{ xs: '90px', sm: '110px' }} />
          <CustomLoadingButton onClick={handleCropSave} name='Save' width={{ xs: '90px', sm: '110px' }} marginTop= '0px' />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventImageUploader;
