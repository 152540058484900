import { useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Box, IconButton } from '@mui/material'

import MobileMenuDrawer from './MobileMenuDrawer'

export default function MobileOnlyMenu(props: any) {
    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState)
    }

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 0.5, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>

            <Box component="nav">
                <MobileMenuDrawer
                    setCurrentLink={props?.setCurrentLink}
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={handleDrawerToggle}
                    currentLink={props?.currentLink}
                />
            </Box>
        </>
    )
}
