import { Box, Button, Card, CardContent, Typography } from '@mui/material'

import { ReactComponent as SendIcon } from '../../../assets/paper-plan-send-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/pen-to-square-solid-blue-icon.svg'
import { ReactComponent as PreviewIcon } from '../../../assets/preview-icon.svg'

export default function PublishedDetails(props: any) {
    return (
        <Card
            sx={{
                display: 'flex',
                mt: 4,
                boxShadow: 'none',
                borderRadius: '0'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 4 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            font: 'normal normal normal 13px/16px Inter'
                        }}
                    >
                        {props?.event?.event?.name}
                    </Typography>

                    <Box
                        sx={{
                            display: { xs: 'flex', sm: 'flex' },
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <Button
                            onClick={props?.sendGreetings}
                            variant="text"
                            startIcon={<SendIcon />}
                            sx={{
                                marginTop: '30px',
                                textTransform: 'capitalize',
                                fontSize: '13px',
                                px: 2,
                                mr: {xs: 2, sm: 0},
                                minHeight: '36px',
                                minWidth: '95px'
                            }}
                        >
                            Resend
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<EditIcon  style={{ width: '16px',height: '16px'}}/>}
                            onClick={props?.setShowUploadFinalVideForm}
                            sx={{
                                marginTop: '30px',
                                textTransform: 'capitalize',
                                fontSize: '13px',
                                px: 2,
                                ml: { xs: 0, sm: 2 },
                                minHeight: '36px',
                                minWidth: '95px'
                            }}
                        >
                            Edit
                        </Button> 
                        {props?.event.event?.publish_video_url && (
                        <Button
                            onClick={props?.setShowPreview}
                            startIcon={<PreviewIcon />}
                            sx={{
                                backgroundColor: 'primary.light',
                                color: 'white',
                                marginTop: '30px',
                                textTransform: 'capitalize',
                                fontSize: '13px',
                                px: 2,
                                ml: 2,
                                mr: {xs: 2, sm: 0},
                                '&:hover': {
                                    backgroundColor: 'primary.dark'
                                },
                                minHeight: '36px',
                                minWidth: '95px'
                            }}
                        >
                            Preview
                        </Button>
                        )}
                    </Box>
                </CardContent>
            </Box>
        </Card>
    )
}
