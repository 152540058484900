import { useEffect, useState } from 'react'


import { useAuth, useSignIn } from '@clerk/clerk-react'
import { Box, Grid } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { useNavigate } from 'react-router-dom'

import { EmailLogin } from './EmailLogin'
import LoginFooter from './LoginFooter'
import { SocialLogin } from './SocialLogin'
import LoginBg from '../../assets/login-bg.png'
import LogoIcon from '../../assets/logo.svg'

export default function Login() {
    const navigate = useNavigate()
    const storedToken = localStorage.getItem('authToken')
    const { signIn } = useSignIn()
    const { isSignedIn } = useAuth()
    const [showSocialLogin, setShowSocialLogin] = useState(true)

    const handleSignInWithSocial = async (authProvider: any) => {
        await signIn?.authenticateWithRedirect({
            strategy: authProvider,
            redirectUrl: '/sso-callback',
            redirectUrlComplete: '/redirecting-home'
        })
    }

    useEffect(() => {

        if (isSignedIn) {
            navigate('/dashboard', { replace: true });
        }
        const handlePopState = () => {
            if (isSignedIn && window.location.pathname === '/login') {
                navigate('/dashboard', { replace: true });
            }
        }
        window.addEventListener('popstate', handlePopState)

        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn, navigate])

    if(storedToken) {
        navigate('/dashboard')
        return<></>
    }

    return (
        <Grid
            container
            component="main"
            sx={{
                backgroundImage: `linear-gradient(0deg, #E2E2E235, #E2E2E235), url(${LoginBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100vw',
                boxSizing:'border-box'  
            }}
        >
            <CssBaseline />
            <Grid
                item
                xs={12}
                sx={{
                    m: 'auto',
                    p: 2,
                    textAlign: 'center',
                    boxShadow: {
                        xs: 'none',
                        sm: '0px 0px 30px #0000000D',
                        md: '0px 0px 30px #0000000D',
                        lg: '0px 0px 30px #0000000D'
                    },
                    borderRadius: '4px',
                    maxWidth: {
                        xs: '100%',
                        sm: '60%',
                        md: '50%',
                        lg: '32%'
                    },
                    bgcolor: { xs: 'transparent', sm: 'white' }
                }}
            >
                <Box
                    component="img"
                    src={LogoIcon}
                    sx={{
                        mb: { xs: 1, sm: 4, md: 2, lg: 2 },
                        mt: { xs: 0, sm: 0, md: 0, lg: 0 }
                    }}
                />
                {showSocialLogin ? (
                    <SocialLogin
                        handleSignInWithSocial={handleSignInWithSocial}
                        setShowSocialLogin={setShowSocialLogin}
                    />
                ) : (
                    <EmailLogin setShowSocialLogin={setShowSocialLogin} />
                )}
                <LoginFooter />
            </Grid>
        </Grid>
    )
}
