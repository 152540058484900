import { useAuth} from '@clerk/clerk-react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'


import HeroBG from '../../../assets/Home/hero-bg.png'
import HeroIcon from '../../../assets/Home/hero-icon.png'

export default function HeroPage() {
    const navigate = useNavigate()
    const { isSignedIn } = useAuth()

    return (
        <Grid
            id="#"
            container
            sx={{
                background: `url(${HeroBG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: { xs: '700px', sm: '500px', md: '500px' },
                width: '100%',
                backgroundAttachment: 'fixed'
            }}
        >
            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        mx: { xs: 2, sm: 3, md: 15 },
                        my: { xs: 2, sm: 3, md: 5 },
                        textAlign: { xs: 'center', sm: 'left' }
                    }}
                >
                    <Typography
                        variant="h3"
                        gutterBottom
                        data-sal="zoom-in"
                        data-sal-delay="300"
                        data-sal-easing="ease-out-bounce"
                        sx={{
                            lineHeight: 1,
                            fontSize: '25px'
                        }}
                    >
                        <Typography
                            component="span"
                            gutterBottom
                            variant="h1"
                            sx={{
                                lineHeight: 0,
                                fontSize: '25px'
                            }}
                        >
                            Celebrate
                        </Typography>
                        <Typography
                            component="span"
                            gutterBottom
                            variant="h1"
                            sx={{
                                fontFamily: `'Jenthill', cursive`,
                                color: 'custom.pink',
                                lineHeight: 0,
                                pr: '10px',
                                fontWeight: '500',
                                fontSize: '25px'
                            }}
                        >
                            Special
                        </Typography>
                        <Typography
                            component="span"
                            gutterBottom
                            variant="h1"
                            sx={{
                                fontFamily: `'Jenthill', cursive`,
                                fontWeight: '500',
                                lineHeight: 0,
                                color: 'custom.orange',
                                fontSize: '25px'
                            }}
                        >
                            Moments
                        </Typography>
                    </Typography>
                    <Typography
                        component="span"
                        gutterBottom
                        variant="h1"
                        sx={{
                            display: 'flex',
                            lineHeight: 1.5,
                            fontSize: '25px'
                        }}
                    >
                        of your life easy and stress-free
                    </Typography>

                    <Typography variant="subtitle1">
                        Whether it is birthdays or weddings, we have everything
                        you need to make your event unforgettable. Our platform
                        is easy-to-use, and affordable.
                    </Typography>

                    <Typography variant="subtitle1">
                        Let us help you create memories that will last a
                        lifetime.
                    </Typography>

                    <Button
                       onClick={isSignedIn ? () => navigate('/dashboard') : () => navigate('/login')}
                        sx={{
                            backgroundColor: 'primary.light',
                            color: 'common.white',
                            textTransform: 'capitalize',
                            fontSize: '13px',
                            px: 2,
                            my: 5,
                            border: '1px solid',
                            borderColor: 'primary.light',
                            height: '50px',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'primary.light'
                            }
                        }}
                    >
                        Try Special Moments
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        pr: { sm: 0, md: 8 },
                        pt: { sm: 10, md: 0 },
                        height: '100%'
                    }}
                >
                    <Box
                        src={HeroIcon}
                        component="img"
                        sx={{
                            width: '100%'
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
