/* eslint-disable camelcase */
import { useState, useEffect, useRef } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import { MediaDisplay } from "../ViewEvent/ManageUploads/MediaViewer/MediaDisplay";

interface Invitee {
    uploader_name: string;
    uploader_email: string;
    message: string;
    document_urls: string[];
}

interface ManageInviteeUploadsProps {
    props: {
        greetings: Invitee[];
    };
}

export default function ManageInviteeUploads({ props }: ManageInviteeUploadsProps) {
    const [showMedia, setShowMedia] = useState<boolean>(false);
    const [selectedInvitee, setSelectedInvitee] = useState<Invitee | null>(null);
    const [isOverflowed, setIsOverflowed] = useState<Record<string, boolean>>({});
    const textRefs = useRef<Record<string, HTMLDivElement | null>>({});

    const getMedia = (invitee: Invitee) => {
        setSelectedInvitee(invitee);
        setShowMedia(true);
    };

    const checkOverflow = (id: string) => {
        const element = textRefs.current[id];
        return element ? element.scrollWidth > element.clientWidth : false;
    };

    useEffect(() => {
        const updateOverflowStatus = () => {
            const statuses: Record<string, boolean> = {};
            Object.keys(textRefs.current).forEach((id) => {
                statuses[id] = checkOverflow(id);
            });
            setIsOverflowed(statuses);
        };

        const resizeObserver = new ResizeObserver(() => {
            updateOverflowStatus();
        });

        Object.values(textRefs.current).forEach((element) => {
            if (element) {
                resizeObserver.observe(element);
            }
        });

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <>
            {!showMedia ? (
                props?.greetings?.map((invitee: Invitee, index) => {
                    const emailId = `email-${index}`;
                    const nameId = `name-${index}`;

                    return (
                        <Box sx={{ width: '100%', mt: 2, paddingX: { xs: 1, sm: 0 } }} key={invitee?.uploader_email}>
                            <Grid
                                container
                                alignItems="center"
                                sx={{
                                    backgroundColor: 'white',
                                    p: 1,
                                    borderRadius: '4px',
                                    boxShadow: '0px 0px 30px #0000000D',
                                    border: '1px solid',
                                    borderColor: 'secondary.contrastText',
                                    width:'100%'
                                }}
                            >
                                <Box sx={{ width: '25%' }}>
                                    <Tooltip
                                        title={
                                            isOverflowed[nameId]
                                                ? invitee.uploader_name
                                                : ''
                                        }
                                        arrow
                                        disableHoverListener={!isOverflowed[nameId]}
                                    >
                                        <Typography
                                            component="div"
                                            ref={(el) => (textRefs.current[nameId] = el)}
                                            sx={{
                                                fontSize: '12px',
                                                color: 'custom.dark',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {invitee?.uploader_name ?? invitee?.uploader_email}
                                        </Typography>
                                    </Tooltip>
                                </Box>

                                <Box sx={{ width: '50%', pl:2 }}>
                                    <Tooltip
                                        title={
                                            isOverflowed[emailId]
                                                ? invitee.uploader_email
                                                : ''
                                        }
                                        arrow
                                        disableHoverListener={!isOverflowed[emailId]}
                                    >
                                        <Typography
                                            component="div"
                                            ref={(el) => (textRefs.current[emailId] = el)}
                                            sx={{
                                                fontSize: '11px',
                                                color: '#555555',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {invitee?.uploader_email}
                                        </Typography>
                                    </Tooltip>
                                </Box>

                                <Box sx={{   marginLeft: 'auto'}}>
                                    {(invitee.document_urls?.length > 0 || invitee.message) && (
                                        <IconButton
                                            onClick={() => getMedia(invitee)}
                                            sx={{
                                                fontSize: '11px',
                                                borderRadius: '4px',
                                                width: '24px',
                                                height: '24px',
                                                backgroundColor: '#e5ebf3'
                                            }}
                                        >
                                            <ArrowForwardIcon
                                                sx={{
                                                    color: 'primary.light',
                                                    fontSize: '16px'
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </Box>
                            </Grid>
                        </Box>
                    );
                })
            ) : (
                selectedInvitee && (
                    <Box sx={{ mt: 2 }}>
                        <MediaDisplay
                            media={selectedInvitee?.document_urls}
                            back={() => setShowMedia(false)}
                            selectedInvitee={selectedInvitee}
                        />
                    </Box>
                )
            )}
        </>
    );
}
