import React, { FC, InputHTMLAttributes } from 'react'

import { Box } from '@mui/system'
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'

import ErrorValidationMessage from '../ErrorMessage/ErrorValidationMessage'
import Label from '../Label/Label'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
    error?: any
    register?: any
    wrapperClass?: string
    className?: string
    placeholder?: any
    xs?: any
    md?: any
    control?: any
    showLabel?: boolean
    helperText?: string | any
    value?: any 
    disablePastDates?: boolean;
    disableFutureDates?: boolean;
    activeDatePicker?: string | any;
    setActiveDatePicker?: (name: string | null) => void; 
}

const CustomDateInput: FC<InputProps> = ({
    control,
    name,
    error,
    placeholder,
    showLabel,
    helperText,
    value,
    disablePastDates=true,
    disableFutureDates=false,
    activeDatePicker,
    setActiveDatePicker,
    ...rest
}) =>  {
    const today = dayjs();

    const handleOpen = () => {
        if (setActiveDatePicker) setActiveDatePicker(name);
    };

    const handleClose = () => {
        if (setActiveDatePicker) setActiveDatePicker(null);
    };


    return(
    <>
        {showLabel && (
            <Box display="flex" alignItems="start" flexDirection="row">
                <Label title={placeholder} sx={{textAlign: "left !important"}}/>
            </Box>
        )}
        <Controller
            {...rest}
            name={name}
            control={control}
            
            render={({ field: { onChange, onBlur, value: fieldValue }}) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        open={activeDatePicker === name}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        value={fieldValue || (value ? dayjs(value) : null)} 
                        onChange={(date) => {onChange(date ? dayjs(date) : null);
                            onBlur();
                        }}
                        sx={{
                            borderRadius: '6px',
                            borderColor: 'secondary.main',
                            color: 'secondary.main',
                            fontSize: 'typography.fontSize',
                            fontWeightRegular: 600,
                            width: '100%',
                            '& .MuiInputBase-root': {
                                height: 37,
                                fontSize: 14,
                                mt: 0
                            },
                            '& .MuiSvgIcon-root': {
                                width: '20px',
                                height: 'auto'
                            }
                        }}
                        minDate={disablePastDates ? today : undefined}
                        maxDate={disableFutureDates ? today : undefined}
                        slotProps={{
                            textField: { 
                                onClick: (e) => { e.stopPropagation(); }, 
                                onKeyDown: (e) => { e.preventDefault(); }
                            },
                            
                            popper: {
                                disablePortal: true,
                                modifiers:[
                                    {
                                        name: 'flip',
                                        enabled: true,
                                        options:{
                                            altBoundary: true,
                                            rootBoundary: 'viewport',
                                            padding: 8
                                        }
                                    },
                                    {
                                        name: 'preventOverflow',
                                        enabled: true,
                                        options: {
                                            altAxis: true,
                                            tether: true
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                </LocalizationProvider>
            )}
        />
            {helperText && (
                <Box mt={1} sx={{ color: 'red', fontSize: '12px' }}>
                    {helperText}
                </Box>
            )}
         {showLabel && error && <ErrorValidationMessage message={error} />}
    </>
)
}
export default CustomDateInput
