import React from 'react'

import { eventService } from '../services/event.service'

export const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>, setFileList: any, setSelectedFiles: any, setErrorMessage: (message: string) => void ) => {
    const unsupportedTypes = [
        'application/pdf', 'application/zip', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    const allowedMimeTypes = ['image/', 'audio/', 'video/'];

    const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];
    
    selectedFiles.forEach((file) => {
        if (allowedMimeTypes.some((type) => file.type.startsWith(type)) && !unsupportedTypes.includes(file.type)) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
    });

    if (invalidFiles.length > 0) {
        setErrorMessage('This file type is not supported, please upload an image, audio, or video to submit your memory');
    } else {
        setErrorMessage('');
    }
        setFileList((prevFiles: File[]) => [...(prevFiles || []), ...validFiles]);
        setSelectedFiles((prevFiles: File[]) => [...(prevFiles || []), ...validFiles]);
    e.target.value= '';
}   


export const handleFileSelectForUploadPublish = (e: React.ChangeEvent<HTMLInputElement>, setFileList: any, setSelectedFiles: any) => {
    if (e.target.files){
        const selectedFiles = Array.from(e.target.files);
        setFileList(selectedFiles); 
        setSelectedFiles(selectedFiles as File[]); 
    }
    e.target.value= '';
}
export const handleFinalSubmit = async (
    userInputs: any, 
    eventId: string | undefined, 
    groupId: string | undefined, 
    uploadedImages: never[] | undefined, 
    inviteType: string | undefined, 
    navigate: any, 
    setBtnLoading: (loading: boolean) => void,
    setErrorMessage: (message: string) => void) => {

    const request = {
        event_id: eventId,
        group_id: groupId,
        document_urls: uploadedImages,
        message: userInputs.message,
        email: userInputs.email,
        name: userInputs.name,
        invite_type: inviteType
    }
  
    if ((uploadedImages && uploadedImages.length === 0) && userInputs.message === ''){
        setBtnLoading(false)
        setErrorMessage("Message or video required to send");
        return
    }
    
    await eventService.uploadDocuments(request)
        .then(() => {
            setBtnLoading(false)
            navigate('/upload-success')
        })
        .catch(() => {
            setBtnLoading(false)
        })
}