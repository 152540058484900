import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import toast from 'react-hot-toast';
 
export default function DownLoadAsZip(media: any) {
    toast.success('The download has started. Your files will be saved shortly');
    const filename = 'All-Files'
    const zip = new JSZip()
    const folder = zip.folder('All-Files')
    media.forEach((media: any) => {
        const blobPromise = fetch(media.url).then((response) => {
            if (response.status === 200 || response.status === 0) {
                return Promise.resolve(response.blob())
            }
            return Promise.reject(new Error(response.statusText))
        })
        const name = media.url.substring(media.url.lastIndexOf('/'))
        folder?.file(name, blobPromise)
    })
 
    zip.generateAsync({ type: 'blob' })
        .then((blob: any) => saveAs(blob, filename))
        .catch()
}
 