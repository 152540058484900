import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'

function OutlinedIconButtons(props: any) {
    return (
        <Button
            disabled={props?.disabled}
            variant="outlined"
            startIcon={
                <Avatar
                    src={props?.icon}
                    sx={{ borderRadius: 0, width: 20, height: 20 }}
                />
            }
            sx={{
                height: '40px',
                width: '240px',
                font: 'normal normal normal 13px/16px Inter',
                textTransform: 'none',
                borderRadius: '4px',
                color: '#383838',
                opacity: 1,
                backgroundColor: 'white',
                fontWeightRegular: 600,
                border: '1px  solid',
                borderColor: 'custom.silver',
                '&:hover': {
                    color: '#0096c7',
                    border: '1px  solid #0096c7'
                },
                '&.Mui-disabled':{
                    opacity: 0.5
                }
            }}
            onClick={props?.onClick}
        >
            {props?.text}
        </Button>
    )
}

export { OutlinedIconButtons }
