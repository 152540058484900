/* eslint-disable max-lines-per-function */
import { useState, useEffect } from 'react'

import { useUser } from '@clerk/clerk-react'
import { Grid, Typography, Button } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { Container } from '@mui/system'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import AllMemories from './AllMemories'
import BirthdayCakeIcon from '../../assets/birthday-cake.svg'
import EventViewHeaderCardBg from '../../assets/EventViewHeaderCardBg.svg'
import Loader from '../../components/Shared/Loader'
import {eventService} from '../../services/event.service'

export default function Index() {
    const { user } = useUser()
    const { eventId } = useParams<{ eventId: string }>()
    const [collectMemories, setCollectMemories] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)
    const  videoUrl = collectMemories?.publish_video_url

    useEffect(() => {
        const getMemoriesCollections = async () => {
            try {
                const memories = await eventService.getEachMemoriesCollection(
                    user?.primaryEmailAddress?.emailAddress as string,
                    eventId as string
                )
                setCollectMemories(memories.data)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                throw new Error
            }
        }
        getMemoriesCollections()
    },[eventId, user?.primaryEmailAddress?.emailAddress])

    const handleWatchVideo = () => {
        const videoPageUrl = `<html><body style="margin:0;padding:0;overflow:hidden;"><video width="100%" height="100%" controls autoplay><source src="${videoUrl}" type="video/mp4"></video></body></html>`;
        const newTab = window.open();
        if (newTab) {
          newTab.document.write(videoPageUrl);
        }
      };
    return (
        <>
        {isLoading && (
            <>
                <Loader />
            </>
        )}
        {!isLoading && (
        <Box  sx={{ width: '100%',display:"flex", justifyContent:"center", paddingX: {xs: '1%',sm: '1%'}}}>
         <Container disableGutters sx={{ width:{xs:'400px', sm:'850px', lg:'825px'}, maxWidth:{xs:'400px',sm:'850px', lg:'825px'} }}>       
          <Box
            sx={{
                backgroundColor: 'primary.light',
                borderRadius: { xs: '4px', sm: '4px' },
                mt:{xs:-3.2, sm:0}
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    background: `transparent url(${EventViewHeaderCardBg}) 0% 0% no-repeat padding-box`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: { xs: '440px', sm: '200px' },
                    width: '100%',
                    borderRadius: '4px',
                    justifyItems:'center'
                }}
            >
                <Grid
                    container
                    component="main"
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        height: { xs: '20px', sm: '100vh' },
                        width: '100%'
                    }}
                >
                    <Grid item xs={12} sm={7.5}>
                        <Box
                            sx={{
                                padding: '40px 30px',
                                width: '100%'
                            }}
                        >
                            <Grid
                                container
                                component="main"
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={10}
                            >
                                <Grid item xs={4} sm={2.80} md={2.75}>
                                    <Box
                                        component="div"
                                        sx={{
                                            width: '100px',
                                            height: '100px',
                                            border: '2px solid white',
                                            borderRadius: '50px',
                                            background: `url(${collectMemories?.event_image_url})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}
                                    />
                                </Grid>
 
                                <Grid item xs={8} sm={9.20} md={9.25} sx={{ pt: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={BirthdayCakeIcon}
                                            sx={{
                                                height: '30px',
                                                width: '30px',
                                                display: 'inline-block',
                                                mr: 1
                                            }}
                                        />
 
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                bgcolor: '#ffffff99',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    font: 'normal normal normal 11px/14px Inter',
                                                    display: 'inline-block',
                                                    p: 0.5,
                                                    m: 'auto'
                                                }}
                                            >
                                                {moment(
                                                 collectMemories?.event_date
                                                    ).format('MM-DD-YYYY')}
                                            </Typography>
                                        </Box>
                                    </Box> 
                                    <Typography
                                        sx={{
                                            font: 'normal normal 600 19px/24px Inter',
                                            color: 'white',
                                            wordBreak:'break-word'
                                        }}
                                    >
                                       {collectMemories?.event_name}&apos;s {collectMemories?.event_type}
                                    </Typography>
                                    <Box>
                                    <Typography
                                            sx={{
                                                font: 'normal normal normal 11px/14px Inter',
                                                color: '#AABBCC',
                                                pt: '5px',
                                                wordBreak:'break-word'
                                            }}
                                        >
                                            {collectMemories?.message}
                                    </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4.5} justifyItems="right" justifyContent="right" >
                        <Card
                            sx={{
                                width: {xs:'292px', sm:'250px'},
                                margin: {
                                    xs: 'auto',
                                    sm: '30px 30px auto auto'
                                },
                                bgcolor: '#08387490'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    bgcolor: 'primary.main',
                                    p: 1
                                }}
                            >
                                <Typography variant="body2" sx={{color: "white"}}>
                                    Click below button to see memories
                                </Typography>
                            </Box>
                            <Box sx={{px: 6, py: 2, display: 'flex',justifyContent: 'center'}}>
                                <Button variant="contained" color="success" onClick={ handleWatchVideo}
                                    disabled={!videoUrl}>
                                    Watch Video
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                       </Grid>
                  </Card>
              </Box>
           <AllMemories props={collectMemories} />
        </Container>
      </Box>
        )}
      </> 
    )
}
