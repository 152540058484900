import { Typography } from '@mui/material';

export default function EllipsisLabel(props: any) {
    return (
            <Typography
                component="p"
                sx={{
                    color: 'white',
                    font: props?.font ? props?.font : '11px',
                    wordBreak:'break-word'
                }}
            >
                {props?.label?.event?.name}&apos;s {props?.label?.event?.event_type}
            </Typography>
    );
}
