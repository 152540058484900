import React, {useState, useEffect, useCallback} from 'react';
 
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import moment from 'moment'
 
import BirthdayCakeIcon from '../../assets/birthday-cake.svg'
import EventViewHeaderCardBg from '../../assets/EventViewHeaderCardBg.svg'
import UserIcon from '../../assets/user.svg'
import EllipsisLabel from '../../components/Shared/Label/EllipsisLabel';
import { EventTimer } from '../../components/Shared/Timer'
 
// eslint-disable-next-line max-lines-per-function
function HeaderCard(props: any) {
    const calculateTimeLeft = useCallback(() => {
        const eventDate = moment(props?.event?.deadline)
        const now = moment()
        const deadline = eventDate.clone().startOf('day')
        const duration = moment.duration(deadline.diff(now))
 
        if(now.isAfter(deadline, 'day')){
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
 
        if (now.isSame(deadline, 'day')){
            const totalSecondsInADay = 24 * 60 * 60
            const expiredSeconds = now.diff(deadline, 'seconds')
            const remainingSeconds = totalSecondsInADay - expiredSeconds
 
            const hours = Math.floor(remainingSeconds / 3600)
            const minutes = Math.floor((remainingSeconds % 3600) / 60)
            const seconds = remainingSeconds % 60
            return { days: 0, hours, minutes, seconds }
        }
            return {
                hours: duration.hours(),
                days: Math.floor(duration.asDays()),
                minutes: duration.minutes(),
                seconds: duration.seconds()
            }
       
       
    }, [props?.event?.deadline])
        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
   
        useEffect(() => {
            const interval = setInterval(() => {
                setTimeLeft(calculateTimeLeft())
            }, 1000)
     
            return () => clearInterval(interval)
        }, [calculateTimeLeft])
       
    return (
        <Box
            sx={{
                backgroundColor: 'primary.light',
                borderRadius: { xs: '4px', sm: '4px' },
                mt:{xs:-3.7, sm:0}
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    background: `transparent url(${EventViewHeaderCardBg}) 0% 0% no-repeat padding-box`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: { xs: '400px', sm: '200px' },
                    width: '100%'
                }}
            >
                <Grid
                    container
                    component="main"
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                         height: { xs: '20px', sm: '100vh' },
                        width: '100%'
                    }}
                >
                    <Grid item xs={12} sm={7.5} alignItems='center'>
                        <Box
                            sx={{
                                padding: '40px 30px',
                                width: '100%'
                            }}
                        >
                            <Grid
                                container
                                component="main"
                                direction="row"
                                alignItems="center"
                            >
                                <Grid item xs={4} sm={3} md={3}>
                                    <Box
                                        component="div"
                                        sx={{
                                            width: '100px',
                                            height: '100px',
                                            border: '2px solid white',
                                            borderRadius: '50px',
                                            background: `url(${
                                                props?.event
                                                    ?.event_image_url !== '' &&
                                                props?.event
                                                    ?.event_image_url !== null
                                                    ? props?.event
                                                          ?.event_image_url
                                                    : UserIcon
                                            })`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}
                                    />
                                </Grid>
 
                                <Grid item xs={8}sm={9} md={9} sx={{ pt: 1, pl: { xs: 2, md:0 } }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={BirthdayCakeIcon}
                                            sx={{
                                                height: '30px',
                                                width: '30px',
                                                display: 'inline-block',
                                                mr: 1
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                bgcolor: '#ffffff99',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    font: 'normal normal normal 11px/14px Inter',
                                                    display: 'inline-block',
                                                    p: 0.5,
                                                    m: 'auto'
                                                }}
                                            >
                                                {moment(
                                                    props?.event?.event_date
                                                ).format('MM-DD-YYYY')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <EllipsisLabel font="normal normal 600 19px/24px Inter" 
                                        label={props} 
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
 
                    <Grid item xs={12} sm={4.5} justifyItems="right" justifyContent="right">
                        <Card
                            sx={{
                                width: {xs:'292px', sm:'250px'},
                                margin: {
                                    xs: 'auto',
                                    sm: '30px 30px auto auto'
                                },
                                bgcolor: '#08387490'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    bgcolor: 'primary.main',
                                    p: 1
                                }}
                            >
                                <Typography variant="body2" sx={{color:"white"}}>
                                    Time left to upload your files
                                </Typography>
                            </Box>
 
                            <Box sx={{px: 3,py: 2, pl:{xs:5.2,sm:2.7,md:2.7}}}>
                                <EventTimer expiryTimestamp={timeLeft} />
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    )
}
 
export { HeaderCard }
 