import { useState, useEffect, useRef } from 'react'
 
import {
    Box,
    Card,
    Checkbox,
    Chip,
    Grid,
    Hidden,
    Tooltip,
    Typography
} from '@mui/material'
 
import { ReactComponent as BellIcon } from '../../../../assets/bell-solid.svg'
import { ReactComponent as RemainderSuccessIcon } from '../../../../assets/remainder-success-icon.svg'
import CustomLoadingButton from '../../../../components/Shared/Button/CustomLoadingButton'
import CustomizedDialogs from '../../../../components/Shared/Dialogs/CustomizedDialogs'
import DialogSuccess from '../../../../components/Shared/Dialogs/DialogSuccess'
import NoDataLabel from '../../../../components/Shared/Label/NoDataLabel'
import {eventService} from '../../../../services/event.service'


interface Invitee {
    email: string;
    name: string;
  }

interface ListInviteesProps {
    event: { event_id: string };
    eventInvites: {
        invites: Array<{ email: string; username: string; invite_status_text: string }>;
    };
}

 
// eslint-disable-next-line max-lines-per-function
function ListInvitees({ event, eventInvites: initialEventInvites}: ListInviteesProps) {
    const [selectedInvitees, setSelectedInvitees] = useState<Invitee[]>([]);
    const [eventInvites, setEventInvites] = useState<any>(initialEventInvites);
    const [checkedInvitees, setCheckedInvitees] = useState<Record<string, boolean>>({});
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [validationMessage, setValidationMessage] = useState('')
    const [isOverflowed, setIsOverflowed] = useState<Record<string, boolean>>({});
    const textRefs = useRef<Record<string, Record<'email' | 'username', HTMLHeadingElement  | null>>>({});
    
    const checkOverflow = (id: string, field: 'email' | 'username') => {
        const element = textRefs.current[id]?.[field];
     if (element) {
        return element.scrollWidth > element.clientWidth;
     }
     return false;
    };
 
    useEffect(() => {
        const updateOverflowStatus = () => {
            const statuses: Record<string, boolean> = {};
            Object.keys(textRefs.current).forEach((id) => {
                ['email', 'username'].forEach((field) => {
                    const key = field as 'email' | 'username'; 
                    if (textRefs.current[id]?.[key]) {
                        statuses[`${id}-${field}`] = checkOverflow(id, field as 'email' | 'username');
                    }
                });
            });
             setIsOverflowed(statuses);
            };
      const resizeObserver = new ResizeObserver(() => {
      updateOverflowStatus();
    });

    Object.values(textRefs.current).forEach((fields) => {
        Object.values(fields).forEach((element) => {
            if (element) {
                resizeObserver.observe(element);
            }
        });
    });

    return () => {
      resizeObserver.disconnect();
    };
    }, []);


    const handleChange = (email: string, name: string) => {
    const isSelected = selectedInvitees.some((item) => item.email === email);
    const newArray = isSelected
      ? selectedInvitees.filter((item) => item.email !== email)
      : [...selectedInvitees, { email, name }];
    setSelectedInvitees(newArray);
    setCheckedInvitees((prev) => ({
        ...prev,
        [email]: !prev[email]
    }));
    };

    const handleSendReminder = async () => {
        const filteredInvitees = selectedInvitees.filter((item) => item.email !== "" && item.email !== null);
        if (filteredInvitees.length === 0) {
            setValidationMessage('You should select at least one invitee');
            return;
        } 
        setValidationMessage('');

        const invitee: any[] = [];
        filteredInvitees.forEach((val) => {
          invitee.push({ email: val.email, name:val.name, group_id: null });
        });
     
        if (filteredInvitees.length > 0) {
          setBtnLoading(true);
          const request = {
            invitees: invitee,
            event_id: event.event_id
          };
          try {
            await eventService.sendRemainderEmail(request);
            setSelectedInvitees([]);
            setCheckedInvitees({});
            setShowSuccessDialog(true);
            setBtnLoading(false);
            setEventInvites((prev:any) => ({
                invites: prev.invites.map((invitee:any) =>
                    filteredInvitees.some((item) => item.email === invitee.email)
                        ? { ...invitee, invite_status_text: 'Reminder' }
                        : invitee
                )
            }));
          } catch (error) {
            setBtnLoading(false);
          }
        }
      };

    return (
        <>
            {eventInvites && eventInvites?.invites?.length > 0 ? 
            (eventInvites?.invites?.map((invitee: any) => (
                <Card
                    key={invitee.email}
                    sx={{
                        boxShadow: '0px 0px 30px #0000000D',
                        border: '1px solid',
                        borderColor: 'secondary.contrastText',
                        borderRadius: '4px',
                        m:{xs:0,sm:2},
                        my:{xs:2}

                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={1.5} sm={1}>
                            <Checkbox
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: { xs: 18, sm: 24 }
                                    }
                                }}
                                value={invitee.email}
                                onChange={() => handleChange(invitee.email, invitee.username)}
                                checked={checkedInvitees[invitee.email] || false}
                            />
                        </Grid>
 
                        <Hidden only="xs">
                            <Grid item xs={6} sm={3}>
                                <Tooltip
                                    title={isOverflowed[`${invitee.email}-username`] ? invitee.username : ""}
                                    arrow
                                    disableHoverListener={!isOverflowed[`${invitee.email}-username`]}
                                >
                                    <Typography
                                        component="h6"
                                        variant="subtitle1"
                                        ref={(el) => {
                                            if (!textRefs.current[invitee.email]){
                                                textRefs.current[invitee.email] = { email: null, username: null };
                                            } 
                                            textRefs.current[invitee.email].username = el;
                                        }}
                                        sx={{
                                            font: 'normal normal normal 13px/14px Inter',
                                            color: 'custom.dark',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            WebkitLineClamp: '1 !important',
                                            WebkitBoxOrient: 'vertical',
                                            pb:0.4
                                        }}
                                    >
                                        {invitee.username}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Hidden>
 
                        <Grid item xs={6.5} sm={5}>
                            <Tooltip
                            title={isOverflowed[`${invitee.email}-email`] ? invitee.email : ""}
                            arrow
                            disableHoverListener={!isOverflowed[`${invitee.email}-email`]}
                            >
                                <Typography
                                    component="h6"
                                    ref={(el) => {
                                        if (!textRefs.current[invitee.email]){
                                            textRefs.current[invitee.email] = { email: null, username: null };
                                        }
                                        textRefs.current[invitee.email].email = el;
                                    }}
                                    sx={{
                                    font: "normal normal normal 11px/14px Inter",
                                    color: "#8D8D8D",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "block"
                                    }}
                                >
                                    {invitee.email}
                                </Typography>
                            </Tooltip>
                        </Grid>
 
                        <Grid item xs={3.5} sm={3}>
                            {invitee.invite_status_text === 'Invited' ? (
                                <Chip
                                    label="Invited"
                                    variant="outlined"
                                    sx={{
                                        fontSize: { xs: '9px', sm: '11px' },
                                        fontWeight:'500',
                                        fontStyle:'normal',
                                        color: 'secondary.main',
                                        bgcolor: 'secondary.contrastText',
                                        borderColor: 'secondary.contrastText',
                                        borderRadius: '4px',
                                        height: { xs: '18px', sm: '26px' },
                                        width: { xs:'70px', sm:'80px'},
                                        ml:{xs:1.5,sm:2.5}
                                    }}
                                />
                            ) : invitee.invite_status_text === 'Submitted' ?  (
                                <Chip
                                    label="Submitted"
                                    variant="outlined"
                                    sx={{
                                        fontSize: { xs: '9px', sm: '11px' },
                                        font: 'normal normal medium 11px/14px Inter',
                                        color: '#7FD14D',
                                        bgcolor: '#f2faed',
                                        borderColor: '#f2faed',
                                        borderRadius: '4px',
                                        height: { xs: '18px', sm: '26px' }, 
                                        width: { xs:'70px', sm:'80px'},
                                        ml:{xs:1.5,sm:2.5}
                                    }}
                                />
                            ) : invitee.invite_status_text === 'Reminder' ?(
                                <Chip
                                    label="Reminder"
                                    variant="outlined"
                                    sx={{
                                        fontSize: { xs: '9px', sm: '11px' },
                                        font: 'normal normal medium 11px/14px Inter',
                                        color: '#FF9E00',
                                        bgcolor: '#fff5e5',
                                        borderColor: '#fff5e5',
                                        borderRadius: '4px',
                                        height: { xs: '18px', sm: '26px' },
                                        width: { xs:'70px', sm:'80px'},
                                        ml:{xs:1.5,sm:2.5}
                                    }}
                                />
                            ):null}
                        </Grid>
                    </Grid>
                </Card>
            ))): (
                <NoDataLabel />
            )}
            {eventInvites?.invites?.length > 0 && (
                <>
                    {validationMessage && (
                        <Typography color="error" sx={{ textAlign: 'center', mb: 1, mt:-1}}>
                        {validationMessage}
                        </Typography>
                    )}

                    <Box sx={{ textAlign: 'right', mt:-3.7,mb:0.5, mr:{xs:0,sm:2}}}>
                        <CustomLoadingButton
                            startIcon={<BellIcon />}
                            onClick={handleSendReminder}
                            loading={btnLoading}
                            name="Send Reminders"
                            width="auto"
                        />
                    </Box>
 
                    <CustomizedDialogs
                        open={showSuccessDialog}
                        onClose={() => setShowSuccessDialog(false)}
                        header="Reminder Sent Successfully"
                        message="The reminders to the invitees are on their way."
                        continueBtn="Manage Invitees"
                        icon={RemainderSuccessIcon}
                        component={DialogSuccess}
                    />
                </>
            )}

 
         
        </>
    )}
export { ListInvitees }