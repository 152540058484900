/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from 'react';

import { Box, Button, Grid, TextField } from '@mui/material';

import { ReactComponent as AddIcon } from '../../../../assets/circle-plus-solid-icon.svg';
import { ReactComponent as InviteSuccessIcon } from '../../../../assets/invite-success-icon.svg';
import { ReactComponent as SendInviteIcon } from '../../../../assets/paper-plane-solid-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash-solid-icon-red.svg';
import CustomLoadingButton from '../../../../components/Shared/Button/CustomLoadingButton';
import CustomizedDialogs from '../../../../components/Shared/Dialogs/CustomizedDialogs';
import DialogSuccess from '../../../../components/Shared/Dialogs/DialogSuccess';
import ErrorValidationMessage from '../../../../components/Shared/ErrorMessage/ErrorValidationMessage';
import isValidEmail from '../../../../hooks/IsValidEmail';
import {eventService} from '../../../../services/event.service';

function AddInviteForm({ eventInvites, addCallBackEvent, event, onInviteUpdate, updateEventInvitesCallBack }: any) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [infoMsg, setInfoMsg] = useState<string | null>(null);
    const [inviteFormFields, setInviteFormFields] = useState([
        { name: '', email: '', isDelete: false }
    ]);
    const [userFormData, setUserFormData] = useState<{
        name: string;
        email: string;
    }>({
        name: '',
        email: ''
    });

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [errors, setErrors] = useState<{ name: string[]; email: string[] }>({
        name: [],
        email: []
      });
    const [focusFlag, setFocusFlag] = useState(false);
    const emailRef = useRef<HTMLInputElement | null>(null);
      
      
    const handleSuccessDialogClose = () => {
        setShowSuccessDialog(false);
        addCallBackEvent(1, 0);
    };

    const handleNameChange = (event: any, index: number) => {
        const {value} = event.target;

        if(index === 0) {
            setUserFormData({ ...userFormData, name: value });
        } else {
            inviteFormFields[index].name = value;
            setInviteFormFields([...inviteFormFields]);
        }
        
    };

    
    const handleEmailChange = (event: any, index: number) => {
        const { value } = event.target;
 
        if(index === 0) {
            setUserFormData({ ...userFormData, email: value });
        } else {
            inviteFormFields[index].email = event.target.value;
            setInviteFormFields([...inviteFormFields]);
        }
    }

    const handleAddInvitee = () => {
        const newErrors = { ...errors };
        let hasError = false;
    
        const normalizedEmail = userFormData.email.trim().toLowerCase();
    
        if (userFormData.name.trim() === '') {
            newErrors.name[0] = '* Required';
            hasError = true;
        } else {
            newErrors.name[0] = '';
        }
    
        if (userFormData.email.trim() === '') {
            newErrors.email[0] = '* Required';
            hasError = true;
        } else if (!isValidEmail(normalizedEmail)) {
            newErrors.email[0] = 'Invalid Email address';
            hasError = true;
        } else if (
            inviteFormFields.some((item, i) => item.email.toLowerCase() === normalizedEmail && i !== 0)
        ) {
            newErrors.email[0] = 'The email address has already been provided.';
            hasError = true;
        } else if (
            eventInvites.some((item: any) => item.email.toLowerCase() === normalizedEmail)
        ) {
            newErrors.email[0] = 'The email address has already been sent invitation.';
            hasError = true;
        } else {
            newErrors.email[0] = '';
        }
    
        setErrors(newErrors);
    
        if (hasError) {
            return;
        }
    
        const userObject = {
            name: userFormData.name.trim(),
            email: normalizedEmail,
            isDelete: true
        };
    
        setUserFormData({ name: '', email: '' });
        setFocusFlag(true);
        setInviteFormFields([...inviteFormFields, userObject]);
        if (infoMsg) {
            setInfoMsg(null);
        }
    };
    
    

    const handleRemoveInvitee = (index: any) => {
        const data = [...inviteFormFields];
        data.splice(index, 1);
        setInviteFormFields(data);
    };

    const handleSendInvitees = async () => {
        setInfoMsg(null);
        const prepareInviteRequest = (invitees: any[]) => {
            const invites = invitees.map((item) => ({
                email: item.email,
                name: item.name.trim()
            }));
    
            return {
                invites,
                event_id: event.event_id
            };
        }

        const sendInviteRequest = async (request: any) => {
                try {
                    setShowSuccessDialog(true);
                    setUserFormData({ name: '', email: '' }); 
                    setInviteFormFields([{ name: '', email: '', isDelete: false }]);
                    setErrors({ name: [], email: [] }); 
                    await eventService.addInvitees(request);
                    updateEventInvitesCallBack()
                    setBtnLoading(false);
                    onInviteUpdate(request?.invites?.length);  
                } catch (error: any) {
                    setBtnLoading(false);
                    if (error?.response?.status === 400) {
                        setInfoMsg('Invalid data, please fill again');
                    } else if (error?.response?.status === 404) {
                        setInfoMsg(error?.response?.data);
                    }
                }
            }

            if (inviteFormFields.length > 0) {
                const validInviteFormFields = inviteFormFields.filter((x) => x.email !== '' && x.name !== '');
        
                if (validInviteFormFields.length > 0) {
                    const request = prepareInviteRequest(validInviteFormFields);
                    await sendInviteRequest(request);
                }
                else if (userFormData.email && userFormData.name.trim()) {
                    const request = prepareInviteRequest([{ email: userFormData.email, name: userFormData.name }]);
                    await sendInviteRequest(request);
                }
            }
        }
        useEffect(() => {
            if (focusFlag) {
              emailRef.current?.focus();
              setFocusFlag(false); 
            }
          }, [focusFlag]);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                {inviteFormFields.map((form: any, index: number) => (
                    <Grid
                        container
                        component="main"
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        key={form.email}
                        sx={{mb:1}}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                id="name"
                                name="name"
                                placeholder="Name"
                                onChange={(event) => handleNameChange(event, index)}
                                value={index === 0 ? userFormData.name : inviteFormFields[index].name}
                                size="small"
                                inputProps={{ style: { fontSize: '14px' }, readOnly: index !== 0 && inviteFormFields[index].isDelete }}
                                sx={{
                                    borderRadius: '4px',
                                    borderColor: 'secondary.contrastText',
                                    color: 'primary.light',
                                    width: '100%',
                                    pt:{xs:1,sm:0}
                                }}
                            />
                            {errors.name[index] && <ErrorValidationMessage message={errors.name[index]} />}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                        >
                            <TextField
                                variant="outlined"
                                id="email"
                                name="email"
                                placeholder="Email"
                                autoFocus
                                onChange={(event) => handleEmailChange(event, index)}
                                value={index === 0 ? userFormData.email : inviteFormFields[index].email}
                                inputRef={index === 0 ? emailRef : null}                             
                                size="small"
                                inputProps={{ style: { fontSize: '14px' },  readOnly: index !== 0 && inviteFormFields[index].isDelete }}
                                sx={{
                                    borderRadius: '4px',
                                    borderColor: 'secondary.contrastText',
                                    color: 'primary.contrastText',
                                    width: '100%'
                                }}
                            />
                            {errors.email[index] && <ErrorValidationMessage message={errors.email[index]} />}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={2}
                            justifyContent="flex-end"
                            container
                            alignItems="center"
                            sx={{mt:{xs:-1,sm:0}}}
                        >
                            {!form.isDelete ? (
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={handleAddInvitee}
                                    startIcon={<AddIcon />}
                                    sx={{
                                        fontSize: '13px',
                                        textTransform: 'capitalize',
                                        mt:{xs:'4px', sm:'0px'},
                                        height:'30px'
                                    }}
                                >
                                    add
                                </Button>
                            ) : (
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => handleRemoveInvitee(index)}
                                    startIcon={
                                        <DeleteIcon style={{ color: 'red' }} />
                                    }
                                    sx={{
                                        fontSize: '11px',
                                        textTransform: 'capitalize',
                                        mt: '4px',
                                        color: 'red'
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Box>
            {infoMsg && <ErrorValidationMessage message={infoMsg} />}
            <Box sx={{ textAlign: 'right', mt:{xs:-4,sm:-2}}}>
                <CustomLoadingButton
                    onClick={handleSendInvitees}
                    startIcon={<SendInviteIcon />}
                    loading={btnLoading}
                    name="Send Invitation"
                    width="auto"
                    disabled={inviteFormFields.length <= 1} 
                />
            </Box>

            <CustomizedDialogs
                open={showSuccessDialog}
                onClose={() => setShowSuccessDialog(false)}
                onManage={handleSuccessDialogClose}
                header="Invitation Sent Successfully"
                message="Your invitation to your invitees is on their way"
                continueBtn="Manage Invitees"
                icon={InviteSuccessIcon}
                component={DialogSuccess}
            />
        </>
    );
}

export { AddInviteForm };
