import { Box, Button, Card, Typography } from '@mui/material';

import { ReactComponent as UploadIcon } from '../../assets/upload-icon.svg';
import { EventTimer } from '../../components/Shared/Timer';

interface TimeCountingProps {
    time: any;
    onClick: () => void;
    publishVideoUrl: string;
    message: string;
}

export default function TimeCounting({ time, onClick, publishVideoUrl, message }: TimeCountingProps) {

    const isButtonDisabled = !!publishVideoUrl || !!message;

    return (
        <Card
            sx={{
                width: {xs:'292px', sm:'250px'},
                height: '200px', 
                margin: { xs: 'auto', sm: '20px' },
                bgcolor: '#08387490',
                display:'flex',
                flexDirection:'column',
                justifyItems:'center',
                alignItems:'center'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    bgcolor: '#1c75bc',
                    p: 1
                }}
            >
                <Typography variant="body2" sx={{ color: "white" }}>
                    Time left to upload your files
                </Typography>
            </Box>

            <Box
                sx={{
                    px: 3,
                    py: 2,
                    display:'flex',
                    flexDirection:'column',
                    justifyItems:'center',
                    alignItems:'center'
                }}
            >
                <EventTimer expiryTimestamp={time} />
                <Button
                    variant="contained"
                    startIcon={<UploadIcon style={{ opacity: isButtonDisabled ? 0.3 : 1 }} />}
                    onClick={onClick}
                    disabled={isButtonDisabled}
                    sx={{
                        width: '104%',
                        mt: 2,
                        height: '40px',
                        color: 'white',
                        bgcolor: '#7FD14D',
                        fontSize: '13px',
                        textAlign: 'center',
                        '&:hover': {
                            bgcolor: '#7FD14D'
                        }
                    }}
                >
                    Upload Memories
                </Button>
            </Box>
        </Card>
    );
}
