import styled from '@emotion/styled';
import { Tab, TabProps } from '@mui/material';

interface StyledTabProps extends TabProps {
  label: string;
}

const StyledTab = styled(Tab)<StyledTabProps>({
  textTransform: 'none',
  color: '#1C1C1C',
  justifyContent: 'left',
  textAlign: 'left',
  display: 'flex',
  backgroundColor: 'transparent',
  fontSize: '13px',
  minHeight: '40px',
  '&.Mui-selected': {
    color: '#1C75BC',
    fontWeight: 600
  }
});

const SideNavBarTab = styled(Tab)<StyledTabProps>({
  textTransform: 'none',
  color: '#1C1C1C',
  justifyContent: 'left',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'transparent',
  gap: '4px',
  fontSize: '13px',
  minHeight: '40px',
  alignItems: 'start',
  padding: '8px 8px',
  '&.Mui-selected': {
    color: '#1C75BC',
    fontWeight: 600,
    border: 12
  }
});

export { StyledTab, SideNavBarTab };
