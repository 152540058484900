import { SyntheticEvent, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material'

import ViewerAudios from './ViewerAudios'
import ViewerImages from './ViewerImages'
import ViewerVideos from './ViewerVideos'
import { TabPanel } from '../../../../components/Shared/Tabs/TabPanel'
import DownLoadAsZip from '../../../../hooks/DownLoadAsZip'
import {
    isAudioUrl,
    isImageUrl,
    isVideoUrl
} from '../../../../hooks/MediaFilter'

function MediaDisplay(props: any) {
    const [value, setValue] = useState(0)
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const images = props?.media?.filter((x: any) => isImageUrl(x.url))
    const videos = props?.media?.filter((x: any) => isVideoUrl(x.url))
    const audios = props?.media?.filter((x: any) => isAudioUrl(x.url))

    return (
        <>
            {props?.media && (
                <>
                    <Grid
                        container
                        component="main"
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item xs={7} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    size="small"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={props?.back}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor:
                                            'secondary.contrastText',
                                        px: 1,
                                        color: 'custom.gray',
                                        padding: {
                                            xs: '10px', 
                                            sm: '4px 10px' 
                                        },
                                        minWidth: {
                                            xs: '10px', 
                                            sm: 'auto' 
                                        },
                                        '&:hover': {
                                            backgroundColor: '#c8c5c5'
                                        },
                                        '& .MuiButton-startIcon': {
                                            marginRight: '0'
                                        },
                                        '&::after': {
                                             content: {
                                                xs: '""',
                                                sm: '""'
                                            }
                                        }
                                    }}
                                />
                                <Typography sx={{
                                    color: "#1C1C1C", 
                                    textAlign: "left", 
                                    fontFamily: "normal normal medium 13px/16px Inter", 
                                    opacity: 1,
                                    paddingLeft: 1,
                                    fontWeight: 500,
                                    fontSize: "16px"
                                }}>
                                    {props?.selectedInvitee?.username}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={5} md={6} textAlign="right">
                            <Button
                                variant="outlined"
                                startIcon={<ArrowCircleDownIcon />}
                                sx={{ textTransform: 'capitalize',
                                    padding:  {
                                        xs: '2px 5px',
                                        sm: '4px 14px',
                                        '@media (min-width:768px)': '30px'
                                    },
                                    '&::after': {
                                        content: {
                                            xs: '"Download"',
                                            sm: '"Download zip"'
                                        }
                                    } 
                                 }}
                                onClick={() => DownLoadAsZip(props?.media)}
                            />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            width: '100%',
                            border: '1px solid',
                            borderColor: 'secondary.contrastText',
                            borderRadius: '6px',
                            mt: 2,
                            mb:2
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            centered
                            sx={{
                                backgroundColor: 'secondary.dark',
                                '.Mui-selected': {
                                    color: 'primary.light',
                                    font: 'normal normal medium 16px/20px Inter'
                                }
                            }}
                            TabIndicatorProps={{
                                color: 'primary.light'
                            }}
                        >
                            <Tab
                                label="Images"
                                sx={{
                                    width: '25%',
                                    textTransform: 'capitalize'
                                }}
                            />
                            <Tab
                                label="Videos"
                                sx={{
                                    width: '25%',
                                    textTransform: 'capitalize'
                                }}
                            />
                            <Tab
                                label="Audio"
                                sx={{
                                    width: '25%',
                                    textTransform: 'capitalize'
                                }}
                            />
                        </Tabs>

                        <Box>
                            <TabPanel value={value} index={0}>
                                <ViewerImages media={images} />
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <ViewerVideos media={videos} />
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                <ViewerAudios media={audios} />
                            </TabPanel>
                        </Box>

                    </Box>
                    { props?.selectedInvitee?.message &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 2,
                            mb:2,
                            p: 2,
                            border: '1px solid',
                            borderColor: 'secondary.contrastText',
                            borderRadius: '6px',
                            backgroundColor: 'background.paper',
                            color: 'text.primary',
                            wordBreak:'break-word'
                        }}
                    >
                        <Typography variant="body1">
                            {props?.selectedInvitee?.message }
                        </Typography>
                    </Box>
                    }
                </>
            )}
        </>
    )
}
export { MediaDisplay }