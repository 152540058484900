import React, { useState } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/system';

interface Option {
    label: string;
    value: string;
}

interface SwitchButtonsProps {
    options: Option[];
    setType: (type: string) => void;
}

function SwitchButtons({ options, setType }: SwitchButtonsProps) {
    const [selectedButton, setSelectedButton] = useState(options[0].value);

    const handleButtonChange = (event: React.MouseEvent<HTMLElement>, newSelectedButton: string | null) => {
        if (newSelectedButton !== null) {
            setSelectedButton(newSelectedButton);
            setType(newSelectedButton);
        }
    };

    return (
        <Box sx={{ bgcolor: '#e5ebf3',borderRadius: '4px',mt:{xs:0,sm:-2}, p: 0.3, width:{sm:'513px', xs: '356px'} ,'@media (max-width: 375px)': {width: '340px'}}}>
            <ToggleButtonGroup
                value={selectedButton}
                exclusive
                onChange={handleButtonChange}
                aria-label="button group"
            >
                {options.map((option) => (
                    <ToggleButton
                        key={option.value}
                        value={option.value}
                        aria-label={option.label}
                        style={{
                            backgroundColor:
                            selectedButton === option.value
                                ? '#023E8A'
                                : '#e5ebf3',
                        color:
                            selectedButton === option.value
                                ? 'white'
                                : '#353535',
                         boxShadow:
                                selectedButton === option.value
                                    ? '4px 4px 8px #0000000D'
                                    : 'none',
                        textTransform: 'none'
                        }}
                        sx={{
                            borderRadius: '4px',
                            border: 'none',
                            width: {
                                xs: '118px', 
                                sm: '170px' 
                            },
                            fontSize: {
                                xs: '9px',
                                sm: '13px'
                            },
                            height: {
                                xs: '28px', 
                                sm: '34px' 
                            },
                            '@media (max-width: 375px)': {
                                width: '112px',
                                fontSize: '7px'
                            }            
                        }}
                    >
                        {option.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
}

export default SwitchButtons;
