import LoadingButton from '@mui/lab/LoadingButton'

export default function CustomLoadingButton(props: any) {
    return (
        <LoadingButton
            loading={props?.loading}
            onClick={props?.onClick}
            startIcon={props?.startIcon}
            disabled={props?.disabled}
            sx={{
                backgroundColor: 'primary.light',
                color: 'white',
                marginTop: props?.marginTop ?? '30px',
                textTransform: 'capitalize',
                fontSize: '13px',
                width: props?.width ?? '100%',
                height: '40px',
                px: 2,
                '&:hover': {
                    backgroundColor: 'primary.dark'
                },
                ' .MuiLoadingButton-loadingIndicator': {
                    color: 'white'
                },
                '&.Mui-disabled':{
                    fontWeight: 200,
                    color: 'white',
                    opacity: 0.5
                },
                ...props?.sx
            }}
        >
            {props?.name}
        </LoadingButton>
    )
}
