import * as React from 'react'

import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';


const BootstrapDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', 
        overflowY: 'auto',       
        maxHeight: '100vh',       
        width: '100%'           
    }
}));


export default function CustomizedDialogs(props: any) {
    const Inner = props?.component;

    const handleClose = (event: any, reason: any) => {
        if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
        props?.onClose();
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} open={props?.open} maxWidth="md">
                <IconButton
                    aria-label="close"
                    onClick={props?.onClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 4,
                        color: (theme) => theme.palette.grey[500],
                        zIndex: 1000000
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    sx={{
                        py: 2,
                        px: { xs: 1, sm: 3 },
                        overflowY: 'auto',            
                        maxHeight: 'calc(100vh - 64px)'
                    }}
                >
                    <Box>
                        <Inner
                            onClose={props?.onClose}
                            onManage={props?.onManage}
                            url={props?.url}
                            header={props?.header}
                            message={props?.message}
                            continueBtn={props?.continueBtn}
                            icon={props?.icon}
                            isClosed={props?.isClosed}
                        />
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
