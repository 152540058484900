import React, { FC, InputHTMLAttributes, useState } from 'react'

import { Box, MenuItem, Select } from '@mui/material'

import ErrorValidationMessage from '../ErrorMessage/ErrorValidationMessage'
import Label from '../Label/Label'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
    error?: any
    register?: any
    wrapperClass?: string
    className?: string
    placeholder?: any
    xs?: any
    md?: any
    control?: any
    showLabel: boolean
    selectOption?: any
    MenuProps?: any
}

const CustomSelectInput: FC<InputProps> = ({
    register,
    name,
    error,
    placeholder,
    showLabel,
    selectOption,
    control,
    MenuProps,
    ...rest
}) => {
    const [isvalue, setIsValue]= useState<string>(placeholder);
     const handleOnChange = (event: any) =>{
       setIsValue(event.target.value);
     }
    return(
    <>
        {showLabel && (
            <Box display="flex" alignItems="start" flexDirection="column">
                <Label title={placeholder} />
            </Box>
        )}
        <Select 
            {...register(name)}
            {...rest}
            variant="outlined"
            name={name}
            placeholder={placeholder}
            control={control}
            size="small"
            value={isvalue}
            onChange={handleOnChange}
            renderValue={(selected) => {
                const selectedOption = selectOption?.find((option: any) => option.value === selected);
                return (
                <em 
                    style={{
                        color: selected===placeholder ? '#6d6464' : '#001233',  
                        font: 'inherit', opacity: 0.9, 
                        fontWeight: selected===placeholder ? 300 : 500,
                        paddingRight: '32px',
                        textAlign: 'center'
                    }}
                >
                    {selectedOption?.icon && (
                        <img
                            src={selectedOption.icon}
                            alt={selectedOption.label}
                            style={{ width: '26px', height: '20px', objectFit: 'contain', marginRight: '5px', verticalAlign: 'middle'}}
                        />
                    )}

                    {selected===placeholder ? "We’re celebrating" :isvalue}
                    
                </em>)
           }}
            
            sx={{
                borderRadius: '4px',
                borderColor: 'secondary.contrastText',
                color: 'primary.contrastText',
                width: '100%',
                '& .MuiButtonBase-root': {
                    marginTop: 0
                }
            }}
            MenuProps={MenuProps}
        >
            {selectOption
                ? selectOption?.map((option: any) => (
                      <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={{
                              marginTop: 0,
                              '& .MuiList-root .MuiButtonBase-root': {
                                  marginTop: 0
                              }
                          }}
                      >
                        {option.icon && (
                            <img
                                src={option.icon}
                                alt={option.label}
                                style={{ width: '26px', height: '19px', objectFit: 'contain', marginRight: '5px'}}
                            />
                        )}
                          {option.label}
                      </MenuItem>
                  ))
                : null}
        </Select>
        {showLabel && error && <ErrorValidationMessage message={error} />}
    </>
)}

export default CustomSelectInput
