
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box,  IconButton } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

const ArrowForward = ({ redirectUrl, eventStatus, eventPublished }: any) => {
    const navigate = useNavigate()
    const location = useLocation()  
    const previousPath = location.pathname
    const isPublished = eventStatus === 'Published';
    const isButtonDisabled =  !eventPublished && isPublished;

    return (
        <Box
            sx={{
                p: 0,
                textAlign: 'right'
            }}
        >
            <IconButton
                disabled={isButtonDisabled}
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{
                    height: '24px',
                    width: '24px',
                    bgcolor: 'custom.blueGray'
                }}
                onClick={() => navigate(redirectUrl , { state: { previousPath } })}
            >
                <ArrowForwardIcon
                    sx={{
                        height: '14px',
                        width: '14px'
                    }}
                />
            </IconButton>
        </Box>
    )
}

export default ArrowForward
